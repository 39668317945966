import React from 'react';
import Layout from "../components/layout"
import {
  Container,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { createContent } from "../common/Theme"

import "../assets/styles/pages/terms-and-conditions.scss";

const useStyles = makeStyles({
  h1: {
    fontSize: '2.2rem',
    marginBottom: '2rem'
  },
  h3: {
    marginBottom: '1rem'
  }
});

const TermsAndConditionsPage = () => {
  const content = createContent();
  const classes = useStyles();

  return (
    <Layout mainClassName='terms-and-conditions'>
      <Container classes={{root: content.content}}>
        <Typography variant='h1' classes={{h1: classes.h1}}>Algemene voorwaarden</Typography>

        <Typography>
          Hieronder vindt u de algemene leveringsvoorwaarden van Syntactful. Deze voorwaarden zijn van toepassing op alle leveringen door Syntactful, met uitsluiting van andersluidende voorwaarden van haar wederpartijen tenzij expliciet anders vermeld wordt in een schriftelijke overeenkomst.
          Door ondertekening van een overeenkomst verklaart elke wederpartij in te stemmen met deze leveringsvoorwaarden, waarvan zij/hij ook verklaart op voorhand kennis te hebben genomen.
        </Typography>

        <Typography>&nbsp;</Typography>

          <ol className='outer-list'>
            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>DEFINITIES</Typography>

                <ul>
                  <li>
                    <strong>Website:</strong> een samenhangend geheel van digitale internetpagina’s inclusief eventuele bijbehorende digitale afbeeldingen, scripts en databases.
                  </li>
                  <li>
                    <strong>Web applicatie:</strong> ook web app, is een software toepassing die toegankelijk is voor de gebruiker via een web browser met een actieve netwerk connectie.
                  </li>
                  <li>
                    <strong>Mobiele applicatie:</strong> ook mobiele app, of simpelweg app genoemd, is een software toepassing ontworpen om gebruikt te worden op een mobiel toestel zoals een smartphone, tablet of smartwatch.
                  </li>
                  <li>
                    <strong>Cloud computing:</strong> het via een netwerk beschikbaar stellen van computer middelen zoals hardware, software en data opslag.
                  </li>
                  <li>
                    <strong>Proof of Concept (POC):</strong> een realisatie van een bepaald idee om de haalbaarheid en het praktisch potentieel hiervan aan te tonen. Een proof of concept is doorgaans klein en is al dan niet compleet.
                  </li>
                  <li>
                    <strong>Minimum Viable Product (MVP):</strong> een versie van een (software) product met net genoeg functionaliteit om bruikbaar te zijn door de eerste gebruikers die vervolgens feedback kunnen geven voor verdere ontwikkeling.
                  </li>
                  <li>
                    <strong>Onderhoud:</strong> het werk dat nodig is om de geleverde software toepassing ter beschikking te stellen en te houden voor de gebruiker. Dit omvat in geen geval het ontwikkelen van nieuwe functionaliteit, tenzij expliciet overeengekomen in een apart onderhoudscontract.
                  </li>
                  <li>
                    <strong>Opdrachtgever:</strong> de natuurlijke of rechtspersoon met wie Syntactful een overeenkomst sluit tot het leveren van diensten.
                  </li>
                </ul>
              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>TOEPASSELIJKHEID</Typography>
                <ol>
                  <li>
                    Door ondertekening van een overeenkomst met Syntactful verklaart de opdrachtgever dat hij kennis heeft genomen van de algemene leveringsvoorwaarden van Syntactful en dat hij met deze leveringsvoorwaarden akkoord gaat.
                  </li>
                  <li>
                    Afwijken van de algemene leveringsvoorwaarden is alleen dan mogelijk, wanneer die expliciet in een schriftelijke overeenkomst tussen de opdrachtgever en Syntactful opgenomen wordt.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>AANBIEDING EN ACCEPTATIE</Typography>

                <ol>
                  <li>
                    Alle offertes en prijsopgaven door Syntactful zijn geheel vrijblijvend, behoudens na een bekomen schriftelijk akkoord met deze offerte.
                  </li>
                  <li>
                    Offertes en prijsopgave door Syntactful blijven 1 maand geldig, tenzij een andere termijn wordt vermeld door Syntactful. Facturering geschiedt op basis van de ondertekening van deze offerte of goedkeuring van de offerte per e-mail en eventueel bestelde of uitgevoerde meerwerken lopende de opdracht.
                  </li>
                  <li>
                    Wijzigingen in de oorspronkelijk gesloten overeenkomst tussen de opdrachtgever en Syntactful zijn pas geldig vanaf het moment dat deze wijzigingen middels een aanvullend of gewijzigde overeenkomst zijn aanvaard door beide partijen.
                  </li>
                  <li>
                    Een samengestelde prijsopgave verplicht Syntactful niet tot het verrichten van een gedeelte van de opdracht tegen een overeenkomstig deel van de opgegeven prijs.
                  </li>
                  <li>
                    Aanbiedingen of offertes gelden niet automatisch voor de toekomstige opdrachten.
                  </li>
                  <li>
                    De opdrachtgever erkent onderling e-mail als wettelijk, geldig bewijsmiddel in toepassing van artikel 2281 van het Burgerlijk Wetboek.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>UITVOERING VAN DE OVEREENKOMST</Typography>

                <ol>
                  <li>
                    Syntactful zal de overeenkomst naar beste inzicht en vermogen en overeenkomstig de eisen van goed vakmanschap uitvoeren.
                  </li>
                  <li>
                    Indien en voor zover een goede uitvoering van de overeenkomst dit vereist, heeft Syntactful het recht bepaalde werkzaamheden te laten verrichten door derden, al dan niet in onderaanneming.
                  </li>
                  <li>
                    De opdrachtgever draagt er zorg voor dat alle gegevens, waarvan Syntactful aangeeft dat deze noodzakelijk zijn of waarvan de opdrachtgever redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn voor het uitvoeren van de overeenkomst, tijdig worden overgemaakt aan Syntactful. Indien deze benodigde gegevens niet tijdig aan Syntactful zijn verstrekt, heeft Syntactful het recht de uitvoering van de overeenkomst op te schorten en/of de uit de vertraging voortvloeiende extra kosten volgens de gebruikelijke tarieven aan de opdrachtgever in rekening te brengen.
                  </li>
                  <li>
                    Syntactful is niet aansprakelijk voor schade, van welke aard ook, te wijten aan door de opdrachtgever verstrekte onjuiste en/of onvolledige gegevens.
                  </li>
                  <li>
                    Indien is overeengekomen dat de overeenkomst in fasen zal worden uitgevoerd, kan Syntactful de uitvoering van die onderdelen die tot een volgende fase behoren opschorten tot dat de opdrachtgever de resultaten van de daaraan voorafgaande fase schriftelijk heeft goedgekeurd.
                  </li>
                  <li>
                    Indien door Syntactful of door opdrachtgever ingeschakelde derden in het kader van de opdracht werkzaamheden worden verricht op de locatie van de opdrachtgever of een door opdrachtgever aangewezen locatie, draagt opdrachtgever kosteloos zorg voor de door die medewerkers in redelijkheid gewenste faciliteiten.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>DUUR EN BEEINDIGING</Typography>
                <ol>
                  <li>
                    Ontwerpen, ontwikkelen, herwerken
                    
                    <ol>
                      <li>
                        Het ontwerpen, ontwikkelen of herwerken van software applicaties is een aannemingsovereenkomst die wordt uitgevoerd in regie. Werken waarvoor geen levertijd voorafgaandelijk is overeengekomen, worden uitgevoerd binnen een redelijke termijn.
                      </li>
                      <li>
                        De uitvoeringstermijn wordt opgeschort in de gevallen zoals bepaald in de artikelen 4.3, 6.3, 7, en 9.
                      </li>
                      <li>
                        De uitvoeringstermijn eindigt met de voorlopige oplevering, die stilzwijgend geschiedt ter gelegenheid van de publicatie.
                      </li>
                    </ol>
                  </li>

                  <li>
                    Algemeen
                    <ol>
                      <li>
                        Syntactful kan een overeenkomst met de opdrachtgever direct beëindigen wanneer de opdrachtgever zich niet, onbehoorlijk of onvolledig houdt aan de met Syntactful gesloten overeenkomsten inclusief de bijhorende leveringsvoorwaarden.
                      </li>
                      <li>
                        Syntactful heeft het recht de overeenkomsten zonder ingebrekestelling of gerechtelijke tussenkomst met onmiddellijke ingang te beëindigen indien de opdrachtgever in staat van faillissement is verklaard, uitstel van betaling of anderszins geheel of gedeeltelijk het vrije beheer over zijn vermogen heeft verloren. De opdrachtgever heeft alsdan geen recht op enige schadevergoeding.
                      </li>
                      <li>
                        Indien stopzetting door klant van een goedgekeurd project is er steeds de gewerkte tijd aan afgesproken budget verschuldigd, plus een schadevergoeding van 30% van het totale project bedrag.
                      </li>
                    </ol>
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>MODALITEITEN VAN LEVERING</Typography>

                <ol>
                  <li>
                    Syntactful gaat na ontvangst van de benodigde gegevens, teksten en/of beeldmateriaal zo spoedig mogelijk van start met het aanmaken van de overeengekomen producten en/of diensten en deelt de opdrachtgever de verwachte levertijd mee.
                  </li>
                  {/*<li>*/}
                  {/*  Bij het ontwerpen van een nieuwe website maakt Syntactful eerst een basisontwerp en legt dit ter goedkeuring voor aan de opdrachtgever. Laatstgenoemde controleert dit ontwerp en geeft commentaar, opmerkingen of aanmerkingen zo spoedig mogelijk door aan Syntactful. Wanneer de opdrachtgever niet binnen een redelijke termijn (die maximaal 30 dagen bedraagt) reageert op het basisontwerp, gaat Syntactful ervan uit dat de opdrachtgever akkoord gaat met het basisontwerp. Na ontvangst van de reacties van de opdrachtgever op het basisontwerp of het binnen een redelijke termijn uitblijven van zulke reacties gaat Syntactful over tot het voltooien van de volledige website.*/}
                  {/*</li>*/}
                  <li>
                    Door Syntactful gemaakte software applicaties worden op een nader te specificeren wijze aan de opdrachtgever verstrekt.
                  </li>
                  <li>
                    Indien in de offertefase een voorschot bedongen werd, is de opdrachtgever, na het handtekenen van de bestelbon, offerte of goedkeuring per e-mail ertoe gehouden het voorschot te betalen. Syntactful behoudt zich het recht de uitvoering van de opdracht op te schorten totdat het overeengekomen voorschot betaald is.
                  </li>
                </ol>
              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>OVERMACHT</Typography>

                <ol>
                  <li>
                    Syntactful aanvaardt geen enkele aansprakelijkheid wanneer Syntactful als gevolg van overmacht of vreemde oorzaak niet aan zijn verplichtingen kan voldoen.
                  </li>
                  <li>
                    In het geval dat de overmacht slechts tijdelijk van aard is, zal Syntactful alsnog aan alle verplichtingen trachten te voldoen vanaf het moment dat dit redelijkerwijs weer mogelijk is. Wanneer onderling overleg tussen de opdrachtgever en Syntactful geconcludeerd wordt dat dit niet meer mogelijk is, zal de overeenkomst in onderling overleg herzien of ontbonden worden. Eventuele reeds geleverde prestaties door Syntactful tot aan het moment van overmacht zullen alsnog gefactureerd worden.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>PRIJZEN</Typography>

                <ol>
                  <li>
                    Alle prijzen ten aanzien van particulieren zijn inclusief BTW, tenzij nadrukkelijk anders vermeld staat. Prijzen aan BTW-plichtigen worden steeds exclusief BTW opgegeven.
                  </li>
                  <li>
                    De opgegeven tarieven zijn in principe vast. Er is evenwel een aanpassing aan de levensduurte (=indexatie) mogelijk van zodra de oorspronkelijk overeengekomen prijs meer dan 1 jaar oud is, of een prijsaanpassing wanneer stijgende wisselkoersen of andere externe oorzaken de grondstoffen- software- of onderdelenprijzen met minstens 10% hebben doen stijgen.
                  </li>
                  <li>
                    Andere tariefwijzigingen worden door Syntactful minimaal 2 maanden van tevoren bekendgemaakt aan de opdrachtgever. Laatstgenoemde is gerechtigd de overeenkomst te beëindigen vanaf het moment dat de aangepaste tarieven van kracht worden, maar enkel in het geval wanneer de tariefwijziging een prijsverhoging zou inhouden. Prijsverlagingen kunnen geen grond op beëindiging opleveren.
                  </li>
                </ol>
              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>BETALING</Typography>
                <ol>
                  <li>
                    Vanaf het moment dat een overeenkomst voor het ontwerpen wordt gesloten tussen de opdrachtgever en Syntactful is de opdrachtgever betalingsplichtig. De opdrachtgever dient uiterlijk op de vervaldag zoals vermeld op de factuur of bij gebreke hieraan binnen de 15 kalenderdagen na de factuurdatum het verschuldigde bedrag te voldoen.
                  </li>
                  <li>
                    Bij een onderhoudsovereenkomst wordt door Syntactful aan de opdrachtgever elke maand een factuur gestuurd. De opdrachtgever dient uiterlijk op de vervaldag zoals vermeld op de factuur of bij gebreke hieraan binnen de 15 kalenderdagen na de factuurdatum het verschuldigde bedrag te voldoen. De periode van onderhoud en de betalingstermijn kan in een andersluidende schriftelijke overeenkomst gewijzigd worden.
                  </li>
                  <li>
                    Bij het gebruikmaken van diensten zoals cloud computing of hosting van externe leveranciers zullen de kosten die hiermee verbonden zijn maandelijks doorgerekend worden aan de opdrachtgever met een marge van 30% voor Syntactful, tenzij in een schriftelijke overeenkomst anders overeengekomen. De opdrachtgever dient uiterlijk op de vervaldag zoals vermeld op de factuur of bij gebreke hieraan binnen de 15 kalenderdagen na de factuurdatum het verschuldigde bedrag te voldoen
                  </li>
                  <li>
                    Vanaf het moment dat de opdrachtgever de betalingstermijn overschrijdt, is hij in gebreke, zonder dat daartoe een aanmaning of ingebrekestelling nodig zou zijn, en is de opdrachtgever de officiële rentevoet verschuldigd inzake de wetgeving op de betalingsachterstand in handelstransacties aan Syntactful over het openstaande bedrag, met ingang van de vervaldag.
                  </li>
                  <li>
                    Daarenboven is alsdan ofwel de werkelijke incassokost door de opdrachtgever verschuldigd, ofwel een schadevergoeding van 10% van het factuurbedrag, met een forfaitair minimum van 200 euro en een maximum van 2000 euro per factuur, naar keuze van Syntactful.
                  </li>
                  <li>
                    In dergelijke gevallen behoudt Syntactful zich het recht voor eventuele lopende werkzaamheden of geleverde diensten tijdelijk te staken, totdat de verschuldigde bedragen zijn ontvangen.
                  </li>
                  <li>
                    Alle protesten inzake de geleverde of gefactureerde diensten dienen op straffe van verval binnen de acht dagen en uitsluitend schrifteljik (per e-mail of aangetekende brief) worden toegezonden aan Syntactful.
                  </li>
                  <li>
                    De opdrachtgever wordt pas eigenaar van of krijgt het gebruiksrecht op de geleverde goederen en/of diensten vanaf het moment dat hij aan al zijn verplichtingen jegens Syntactful heeft voldaan.
                  </li>
                  <li>
                    Indien Syntactful haar verplichtingen uit deze overeenkomst niet zou naleven, heeft de opdrachtgever ook het recht om een schadevergoeding te eisen, die echter niet hoger kan zijn dan de vergoedingen die de opdrachtgever verschuldigd zou zijn conform artikel 9.4 en 9.5, doch en ook voor zover de fout en de geleden schade door de opdrachtgever wordt bewezen.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>CLOUD COMPUTING EN HOSTING</Typography>

                <ol>
                  <li>
                    Voor de hosting van websites, registratie van domeinnamen en cloud computing voor het ontwikkelen en ter beschikking stellen van software applicaties doet Syntactful beroep op gespecialiseerde externe leveranciers.
                  </li>
                  <li>
                    De kosten die verbonden zijn aan de diensten van externe leveranciers worden maandelijks doorgefactureerd aan de opdrachtgever met een marge van 30%, tenzij schriftelijk anders overeengekomen werd.
                  </li>
                  <li>
                    Syntactful zal naar beste inzicht en vermogen de meest kostenefficiënte diensten gebruiken van externe leveranciers naar de noden van de gevraagde werken.
                  </li>
                  <li>
                    Indien de betaling van één of meerdere van deze doorgerekende facturen uitblijft kan Syntactful de diensten stopzetten bij de externe leverancier(s) met als gevolg de deactivatie van de geleverde website of software applicatie. Het oorspronkelijke factuurbedrag blijft onverminderd verschuldigd. Syntactful is niet verantwoordelijk voor schade of andere gevolgen veroorzaakt door deze deactivatie.
                  </li>
                  <li>
                    De opdrachtgever kan aanvragen om deze diensten van externe leveranciers stop te zetten. Bij stopzetting heeft dit de deactivatie van de geleverde website of software applicatie tot gevolg. Alle reeds gemaakte kosten tot aan de eigenlijke stopzetting zullen alsnog doorgefactureerd worden.
                  </li>
                  <li>
                    De opdrachtgever staat zelf in voor de transfer van een website of software applicatie bij het stopzetten van de diensten van externe leveranciers. Eventuele assistentie hierbij zal aangerekend worden aan het op dat moment geldende uurtarief.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>COPYRIGHT</Typography>
              
                <ol>
                  <li>
                    Al het door Syntactful vervaardigde materiaal mag zonder de uitdrukkelijke toestemming van Syntactful niet worden bewerkt of verwerkt in andere websites dan waarvoor het oorspronkelijk gemaakt is.
                  </li>
                  <li>
                    De eigendom van door Syntactful verstrekte ideeën, concepten of ontwerpen blijft volledig bij Syntactful, tenzij uitdrukkelijk schriftelijk anders overeengekomen wordt. In het laatste geval kan Syntactful hiervoor een vergoeding bedingen. Bij gebleken schending van het genoemde eigendom is Syntactful gerechtigd hiervoor een zelf vast te stellen doch redelijke vergoeding in rekening te brengen.
                  </li>
                  <li>
                    Syntactful behoudt het recht de door de uitvoering van de werkzaamheden toegenomen kennis voor andere doeleinden te gebruiken, voor zover hierbij geen vertrouwelijke informatie ter kennis van derden wordt gebracht.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>AANSPRAKELIJKHEID</Typography>
                <ol>
                  <li>
                    Voor zover Syntactful bij haar activiteiten afhankelijk is van de medewerking, diensten en leveranties van derden, waarop Syntactful weinig of geen invloed kan uitoefenen kan Syntactful op geen enkele manier aansprakelijk worden gesteld voor welke schade dan ook voortkomend uit deze relaties met Syntactful of het verbreken ervan ongeacht of deze schade ontstaat of zichtbaar wordt gedurende de relatie met Syntactful.
                  </li>
                  <li>
                    In geval van toerekenbare tekortkoming in de nakoming van de overeenkomst is Syntactful slechts aansprakelijk voor de vervangende schadevergoeding conform artikel 9.9. Iedere aansprakelijkheid van Syntactful voor enige andere vorm van schade is uitgesloten, daaronder begrepen vergoeding van indirecte schade, gevolgschade of schade wegens gederfde omzet of winst.
                  </li>
                  <li>
                    De opdrachtgever dient er rekening mee te houden dat informatie die via internet verzonden wordt, door derden onderschept kan worden. Syntactful kan niet aansprakelijk gesteld worden voor schade in welke vorm dan ook veroorzaakt door het verzenden van vertrouwelijke of geheime informatie. De opdrachtgever zal zich hiertegen zelf afdoende verzekeren, met afstand van verhaal jegens Syntactful en vrijwaring van deze laatste in hoofdsom, interesten en kosten.
                  </li>
                  <li>
                    Syntactful is niet verantwoordelijk of aansprakelijk voor de inhoud van het door de opdrachtgever aangeleverde materiaal dat is aangebracht in het door Syntactful ontwikkelde product.
                  </li>
                  <li>
                    Syntactful is evenmin verantwoordelijk voor de links die in het door Syntactful ontwikkelde product worden aangebracht. De opdrachtgever zal er zelf voor instaan dat hij de nodige toestemmingen en het recht heeft om links te plaatsen naar externe websites.
                  </li>
                  <li>
                    De inhoud van de gegevensverspreiding en –publicatie blijft in alle gevallen de eigen verantwoordelijkheid van de opdrachtgever. Syntactful wordt niet geacht deze te beperken of erop toe te zien, noch kan Syntactful aansprakelijk worden gesteld voor de inhoud van de eigen publicatie. Alle nodige auteursrechten, vergoedingen, onkosten of boetes zijn ten laste van de opdrachtgever.
                  </li>
                  <li>
                    De opdrachtgever zal Syntactful vrijwaren voor elke vordering in hoofdsom, interesten en kosten aangaande zaken waarvoor hij zelf moet instaan.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>COORDINATEN</Typography>
              
                <ol>
                  <li>
                    De opdrachtgever dient Syntactful terstond schriftelijk op de hoogte te stellen van wijzigingen in opdrachtgevers gegevens.
                  </li>
                  <li>
                    Dit geldt voor elke adreswijziging, wijziging in contactpersoon of bevoegde persoon, telefoon-, faxgegevens, e-mailadressen, domeinnamen, enz...
                  </li>
                  <li>
                    Wanneer de opdrachtgever dit nalaat, is laatstgenoemde volledig aansprakelijk voor eventuele hierdoor veroorzaakte door Syntactful geleden schade.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>OVERDRACHT RECHTEN EN PLICHTEN</Typography>
              
                <ol>
                  <li>
                    Syntactful noch de opdrachtgever zijn gerechtigd hun rechten of plichten voortvloeiend uit gesloten overeenkomsten over te dragen aan een derde partij zonder voorafgaande schriftelijke toestemming van de wederpartij.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>DIVERSE BEPALINGEN</Typography>
              
                <ol>
                  <li>
                    Syntactful zal geen persoonlijke gegevens van de opdrachtgever aan derden verstrekken zonder dat daar een wettelijke verplichting toe bestaat. Dit geldt tevens voor eventuele vertrouwelijk informatie die ten behoeve van het uitvoeren van een overeenkomst verstrekt is aan Syntactful.
                  </li>
                  <li>
                    De identiteitsgegevens van de opdrachtgever worden bewaard in onze database, met het oog op intern gebruik. De opdrachtgever heeft steeds het recht schriftelijk wijziging of verwijdering te vragen van zijn gegevens.
                  </li>
                  <li>
                    De opdrachtgever geeft door aanvaarding van deze voorwaarden de toestemming tot het gebruik en de mededeling van zijn identiteitsgegevens voor promotionele doeleinden. De opdrachtgever kan echter steeds schriftelijk wijziging of verwijdering vragen van zijn gegevens uit onze database.
                  </li>
                  <li>
                    Wanneer dit nodig mocht zijn, kunnen wijzigingen in een overeenkomst na schriftelijke bevestiging van beide partijen doorgevoerd worden. Hierdoor kan het overeengekomen tijdstip van oplevering veranderen. Syntactful zal de opdrachtgever zo spoedig mogelijk van de nieuwe opleveringsdatum en eventuele financiële consequenties op de hoogte stellen.
                  </li>
                  <li>
                    Syntactful is vrij om voor promotiedoeleinden als referentie te verwijzen naar de geleverde eindproducten, tenzij nadrukkelijk anders is overeengekomen.
                  </li>
                </ol>
              

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>GESCHILLEN EN TOEPASSELIJK RECHT</Typography>
              
                <ol>
                  <li>
                    Indien bij gerechtelijke uitspraak een of meer artikelen van deze voorwaarden ongeldig worden verklaard, zullen overige bepalingen van deze algemene voorwaarden volledig van kracht blijven en zullen Syntactful en opdrachtgever in overleg treden teneinde nieuwe bepalingen ter vervanging van de nietige, dan wel vernietigde bepalingen op te maken.
                  </li>
                  <li>
                    Op alle onder deze voorwaarden gedane aanbiedingen en gesloten overeenkomsten is uitsluitend het Belgisch recht van toepassing.
                  </li>
                  <li>
                    Onverminderd bovenstaand gegeven, en voor alle andere kwesties, zullen alle geschillen, direct of indirect uit de overeenkomst voortvloeiend of daarmee verband houdend, bij de Belgische territoriaal bevoegde rechter te Turnhout aanhangig worden gemaakt, die uitsluitend bevoegd is.
                  </li>
                  <li>
                    Deze voorwaarden zijn onder voorbehoud van drukfouten.
                  </li>
                  <li>
                    Van toepassing is steeds de laatste versie zoals die gold ten tijde van het tot stand komen van de overeenkomst.
                  </li>
                </ol>

              <Typography>&nbsp;</Typography>
            </li>

            <li className='outer-list-item'>
              <Typography variant='h3' classes={{h3: classes.h3}}>NIET-EXCLUSIVITEIT EN NIET-AFWERVING</Typography>
              
              <ol>
                <li>
                  Het staat de leverancier vrij om aan concurrenten van de opdrachtgever diensten te leveren. De leverancier zal er op toezien dat – in de mate van het mogelijke – andere personen voor beide opdrachtgevers werken.
                </li>
                <li>
                  De opdrachtgever zal zich ervan onthouden om werknemers, bestuurders, zaakvoerders of vaste zelfstandige dienstverleners van de leverancier in dienst op datum van beëindiging van de overeenkomst of die tot 12 maanden voordien tewerkgesteld waren, aan te werven of te werk te stellen. Deze bepaling blijft ook na beëindigen van de overeenkomst van kracht gedurende 12 maanden. Elke inbreuk zal aanleiding geven tot het betalen van een forfaitaire schadevergoeding van 50.000 EUR.
                </li>
              </ol>

              <Typography>&nbsp;</Typography>
            </li>
          </ol>

        <Typography>
          Laatste gepubliceerde versie van 13/09/2021.
        </Typography>

      </Container>
    </Layout>
  )
}

export default TermsAndConditionsPage;
